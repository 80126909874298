const _temp0 = require("../img/blog-1.jpg");
const _temp1 = require("../img/blog-2.jpg");
const _temp2 = require("../img/blog-3.jpg");
const _temp3 = require("../img/blog-4.jpg");
const _temp4 = require("../img/blog-5.jpg");
const _temp5 = require("../img/testimonial-1.jpg");
const _temp6 = require("../img/testimonial-2.jpg");
const _temp7 = require("../img/testimonial-3.jpg");
const _temp8 = require("../img/testimonial-4.jpg");
const _temp9 = require("../img/testimonial-5.jpg");
const _temp10 = require("../img/testimonial-6.jpg");
const _temp11 = require("../img/testimonial-7.jpg");
const _temp12 = require("../img/testimonial-8.jpg");
const _temp13 = require("../img/testimonial-9.jpg");
module.exports = {
  "blog-1": _temp0,
  "blog-2": _temp1,
  "blog-3": _temp2,
  "blog-4": _temp3,
  "blog-5": _temp4,
  "testimonial-1": _temp5,
  "testimonial-2": _temp6,
  "testimonial-3": _temp7,
  "testimonial-4": _temp8,
  "testimonial-5": _temp9,
  "testimonial-6": _temp10,
  "testimonial-7": _temp11,
  "testimonial-8": _temp12,
  "testimonial-9": _temp13
}