//TESTIMONIAL SLIDER //
import Swiper, { Navigation, Pagination } from 'swiper';
import 'swiper/swiper-bundle.min.css';
import intlTelInput from 'intl-tel-input';
import utils from 'intl-tel-input/build/js/utils';
import 'intl-tel-input/build/css/intlTelInput.css';
import dotenv from 'dotenv';
import images from '../img/*.jpg';

dotenv.config();

const swiper = new Swiper('.testimonial__slide-container', {
  modules: [Navigation],
  autoHeight: true,
  pagination: {
    el: '.swiper-pagination',
    clickable: true,
    dynamicBullets: true,
  },
  keyboard: {
    enabled: true,
    onlyInViewport: true,
  },

  // Navigation arrows
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  autoHeight: true,
  breakpoints: {
    768: {
      navigation: false,
      slidesPerView: 2,
      spaceBetween: 20,
    },
    1024: {
      slidesPerView: 3,
      spaceBetween: 30,
    },
  },
  grabCursor: true,
  autoplay: {
    delay: 10000,
  },
});

//BLOG SLIDER//
const swiper1 = new Swiper('.blog__container', {
  autoHeight: true,
  slidesPerView: 'auto',
  grabCursor: true,
  breakpoints: {
    768: {
      slidesPerView: 1,
    },
    1024: {
      slidesPerView: 3,
      spaceBetween: 30,
    },
  },
});

////////HAMBURGER MENU//////
const body = document.querySelector('body');
const overlay = document.querySelector('.overlay');
const navigationMobile = document.querySelector('.navigation__mobile');
const btnHamburger = document.querySelector('.hamburger');
btnHamburger.addEventListener('click', () => {
  overlay.classList.toggle('open');
  body.classList.toggle('noscroll');
  navigationMobile.classList.toggle('open');
  btnHamburger.classList.toggle('is-active');
});

const closeMenu = () => {
  overlay.classList.remove('open');
  navigationMobile.classList.remove('open');
  body.classList.remove('noscroll');
  btnHamburger.classList.remove('is-active');
};

overlay.addEventListener('click', () => {
  closeMenu();
});

///////MENU MOBILE/////
const mobileMenu = document.querySelector('.navigation__mobile--menu');
const mobileMenuLinks = document.querySelectorAll('.navigation__mobile-link');
let isMenuOpen = false;

mobileMenu.addEventListener('click', (e) => {
  closeMenu();
  isMenuOpen ? (isMenuOpen = false) : (isMenuOpen = true);
  mobileMenuLinks.forEach((link) => link.classList.remove('active'));

  const clickedMenu = e.target.closest('.navigation__mobile-link');

  isMenuOpen
    ? clickedMenu.classList.add('active')
    : clickedMenu.classList.remove('active');

  const dropData = clickedMenu.getAttribute('data-submenu');

  if (!dropData) return;

  document
    .querySelector(`.mobile__dropdown-${dropData}`)
    .classList.toggle('show');
});

// //MENU DROPDOWN//
// const menuLanguage = document.querySelector('.language-menu');

// document.addEventListener('click', (e) => {
//   const isLanguageButton = e.target.matches('.language');
//   const closest = e.target.closest('.language');

//   if (!isLanguageButton && closest != null) return;
//   if (!isLanguageButton && closest == null)
//     menuLanguage.classList.remove('active');

//   if (isLanguageButton) menuLanguage.classList.toggle('active');
// });

//OUR SOLUTIONS TAB ///
const solutionContainer = document.querySelector('.solutions__features');
const solutionTabsContent = document.querySelectorAll(
  '.solutions__features-content'
);
const ourSolutionsLinks = document.querySelectorAll(
  '.solutions__features-link'
);

solutionContainer.addEventListener('click', (e) => {
  ourSolutionsLinks.forEach((link) => link.classList.remove('active'));
  const clicked = e.target.closest('.solutions__features-link');
  clicked.classList.add('active');

  solutionTabsContent.forEach((tab) => tab.classList.remove('active'));
  const data = clicked.getAttribute('data-tab');

  document.querySelector(`.content--${data}`).classList.add('active');
});

const phoneInput = document.querySelector('#phone');

intlTelInput(phoneInput, {
  initialCountry: 'br',
  separateDialCode: true,
  utilsScript: utils,
  preferredCountries: ['br', 'es', 'us'],
});

//////TESTIMONIAL////
// const testimonialSlider = document.querySelector('.swiper-wrapper');
// testimonialSlider.style.height = '100%';

// const testimonialsData = [
//   {
//     author: 'Josepha Albert Morgan',
//     quote:
//       'Lörem ipsum lal langen om dingar i polyssade. Kvasingen stereogyn: hypolig. \nFonogram pang ryggsäcksmodellen. Langen perfas samt tesm nyst. Genyssade niton.',
//     position: 'CO-Founder',
//     image: 'testimonial-1',
//   },
//   {
//     author: 'Josepha Albert Morgan',
//     quote:
//       'Lörem ipsum lal langen om dingar i polyssade. Kvasingen stereogyn: hypolig. \nFonogram pang ryggsäcksmodellen. Langen perfas samt tesm nyst. Genyssade niton.',
//     position: 'CO-Founder',
//     image: 'testimonial-2',
//   },
//   {
//     author: 'Josepha Albert Morgan',
//     quote:
//       'Lörem ipsum lal langen om dingar i polyssade. Kvasingen stereogyn: hypolig. \nFonogram pang ryggsäcksmodellen. Langen perfas samt tesm nyst. Genyssade niton.',
//     position: 'CO-Founder',
//     image: 'testimonial-3',
//   },
//   {
//     author: 'Josepha Albert Morgan',
//     quote:
//       'Lörem ipsum lal langen om dingar i polyssade. Kvasingen stereogyn: hypolig. \nFonogram pang ryggsäcksmodellen. Langen perfas samt tesm nyst. Genyssade niton.',
//     position: 'CO-Founder',
//     image: 'testimonial-4',
//   },
//   {
//     author: 'Josepha Albert Morgan',
//     quote:
//       'Lörem ipsum lal langen om dingar i polyssade. Kvasingen stereogyn: hypolig. \nFonogram pang ryggsäcksmodellen. Langen perfas samt tesm nyst. Genyssade niton.',
//     position: 'CO-Founder',
//     image: 'testimonial-5',
//   },
//   {
//     author: 'Josepha Albert Morgan',
//     quote:
//       'Lörem ipsum lal langen om dingar i polyssade. Kvasingen stereogyn: hypolig. \nFonogram pang ryggsäcksmodellen. Langen perfas samt tesm nyst. Genyssade niton.',
//     position: 'CO-Founder',
//     image: 'testimonial-6',
//   },
//   {
//     author: 'Josepha Albert Morgan',
//     quote:
//       'Lörem ipsum lal langen om dingar i polyssade. Kvasingen stereogyn: hypolig. \nFonogram pang ryggsäcksmodellen. Langen perfas samt tesm nyst. Genyssade niton.',
//     position: 'CO-Founder',
//     image: 'testimonial-7',
//   },
//   {
//     author: 'Josepha Albert Morgan',
//     quote:
//       'Lörem ipsum lal langen om dingar i polyssade. Kvasingen stereogyn: hypolig. \nFonogram pang ryggsäcksmodellen. Langen perfas samt tesm nyst. Genyssade niton.',
//     position: 'CO-Founder',
//     image: 'testimonial-8',
//   },
//   {
//     author: 'Josepha Albert Morgan',
//     quote:
//       'Lörem ipsum lal langen om dingar i polyssade. Kvasingen stereogyn: hypolig. \nFonogram pang ryggsäcksmodellen. Langen perfas samt tesm nyst. Genyssade niton.',
//     position: 'CO-Founder',
//     image: 'testimonial-9',
//   },
// ];

// const testimonialComponent = (id, author, quote, position) => {
//   return `
//   <div class="swiper-slide testimonial__slide-card">
//     <img class="test_photo-${id + 1} testimonial__photo"  alt="" data-id="Elis">
//     <img class="testimonial__rating" src="app/img/rating-stars.png" alt="">
//     <blockquote class="testimonial__quote">${quote}</blockquote>
//     <address class="testimonial__author">
//         <h6 class="testimonial__name">${author}</h6>
//         <p class="testimonial__location">${position}</p>
//     </address>
//   </div>`;
// };

// testimonialsData.forEach((element, index) => {
//   const id = index + 1;
//   const testimonial = testimonialComponent(
//     index,
//     element.author,
//     element.quote,
//     element.position
//   );
//   testimonialSlider.insertAdjacentHTML('beforeend', testimonial);
//   document.querySelector(`.test_photo-${id}`).src = images[element.image];
// });

/////// STICKY NAVIGATION ////////
const heroSection = document.querySelector('.hero');
const header = document.querySelector('.header');
const dummyHeader = document.querySelector('.dummy_header');

const observerCallback = (entries) => {
  const [entry] = entries;

  if (!entry.isIntersecting) {
    header.classList.add('sticky');
    dummyHeader.classList.add('active');
  } else {
    window.addEventListener('load', () => {
      header.classList.remove('out');
    });
    header.classList.add('out');
    setTimeout(() => {
      header.classList.remove('out');
      header.classList.remove('sticky');
      dummyHeader.classList.remove('active');
    }, 500);
  }
};
const observerOption = {
  root: null,
  threshold: 0,
  rootMargin: '-85px',
};

const headerObserver = new IntersectionObserver(
  observerCallback,
  observerOption
);

headerObserver.observe(heroSection);


// --------------------------- LOGICA DO FORMULARIO DEBAIXO --------------------------- //
function enviarFormulario(event) {
  event.preventDefault();
  // Obter os valores dos campos do formulário
  const dialCode = document.querySelector('.iti__selected-dial-code').innerHTML.split('+')[1];
  const celular = dialCode + document.getElementById('phone').value.replace(/\D/g, '');
  const nome = document.getElementById('name').value;
  const email = document.getElementById('email').value;
  const empresa = document.getElementById('company').value;
  const tipoETamanho = document.getElementById('type-and-size');
  const tipoETamanhoSelecionado = tipoETamanho.options[tipoETamanho.selectedIndex].text;
  const segmento = document.getElementById('line-of-business');
  const segmentoSelecionado = segmento.options[segmento.selectedIndex].text;
  const jaUsouOutroChecklistSelecionado = document.querySelector('input[name="choice-radio"]:checked');
  const textoDaLabelDoRadioSelecionado = jaUsouOutroChecklistSelecionado ? document.querySelector('label[for="' + jaUsouOutroChecklistSelecionado.id + '"]').innerText : undefined;
  const quaisChecklistsJaUtilizou = document.getElementById('quais').value;

  const quantidadeDeCamposObrigatoriosNaoPreenchidos = [];
  
  if (document.getElementById('quais').required && document.getElementById('quais').value === '') {
    document.getElementById('quais_bottom_line').classList.add('bottom-line-error');
    document.getElementById('quais').style.borderColor = 'red';
    quantidadeDeCamposObrigatoriosNaoPreenchidos.push('quais');
  }

  if (document.getElementById('name').required && document.getElementById('name').value === '') {
    document.getElementById('name_bottom_line').classList.add('bottom-line-error');
    document.getElementById('name').style.borderColor = 'red';
    quantidadeDeCamposObrigatoriosNaoPreenchidos.push('name');
  }

  const emailRegex = new RegExp('^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,6}$');
  if (document.getElementById('email').required && (document.getElementById('email').value === '' || !emailRegex.test(document.getElementById('email').value))) {
    document.getElementById('email_bottom_line').classList.add('bottom-line-error');
    document.getElementById('email').style.borderColor = 'red';
    quantidadeDeCamposObrigatoriosNaoPreenchidos.push('email');
  }

  if (document.getElementById('phone').required && document.getElementById('phone').value === '') {
    document.getElementById('phone_bottom_line').classList.add('bottom-line-error');
    document.getElementById('phone').style.borderColor = 'red';
    quantidadeDeCamposObrigatoriosNaoPreenchidos.push('phone');
  }

  
  const radioIndependente = document.getElementById('opcao-independente');
  if (!radioIndependente.checked) {
    if (document.getElementById('company').required && document.getElementById('company').value === '') {
      document.getElementById('company_bottom_line').classList.add('bottom-line-error');
      document.getElementById('company').style.borderColor = 'red';
      quantidadeDeCamposObrigatoriosNaoPreenchidos.push('company');
    }
  
    if (document.getElementById('type-and-size').required && tipoETamanho.selectedIndex === 0) {
      document.getElementById('type-and-size').style.borderColor = 'red';
      quantidadeDeCamposObrigatoriosNaoPreenchidos.push('type-and-size');
    }
  
    if (document.getElementById('line-of-business').required && segmento.selectedIndex === 0) {
      document.getElementById('line-of-business').style.borderColor = 'red';
      quantidadeDeCamposObrigatoriosNaoPreenchidos.push('line-of-business');
    }
  }


  if (quantidadeDeCamposObrigatoriosNaoPreenchidos.length > 0) return;

  const mensagem = document.querySelector('#mensagem');

  const dadosDeAssinatura = {
    nome,
    email,
    celular,
    Empresa: empresa,
    TipoETamanho: tipoETamanhoSelecionado,
    Segmento: segmentoSelecionado,
    JaUsouOutroChecklist: textoDaLabelDoRadioSelecionado === undefined ? "Não respondido" : textoDaLabelDoRadioSelecionado,
    ChecklistsUtilizados: quaisChecklistsJaUtilizou,
    TipoDeAuditor: radioIndependente.checked ? "Independente" : "Empresa",
  };

  fetch(process.env.API + '/api/ServicoDeEmail/PedirAssinatura', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(dadosDeAssinatura)
  })
  .then(response => {
    if (response.ok) {
      mensagem.textContent = 'Formulário enviado com sucesso!';
      mensagem.classList.remove('caixa_mensagem_erro');
      mensagem.classList.add('caixa_mensagem_sucesso');
      // redirecionar para a página de sucesso
      window.location.href = process.env.HOST + '/registergrj';
    } else {
      mensagem.textContent = 'Não foi possível enviar o formulário.';
      mensagem.classList.remove('caixa_mensagem_sucesso');
      mensagem.classList.add('caixa_mensagem_erro');
    }
  })
  .catch(error => {
    mensagem.textContent = 'Não foi possível enviar o formulário.';
    mensagem.classList.remove('caixa_mensagem_sucesso');
    mensagem.classList.add('caixa_mensagem_erro');
  })
  .finally(() => {
    mensagem.classList.remove('d-none');
  });
}

document.getElementById('enviar-formulario').addEventListener('click', enviarFormulario);


let telefone = document.getElementById('phone');

telefone.addEventListener('input', function (e) {
  const telefoneValor = telefone.value;
  const telefoneRegex = /^(\d{2})(\d{4,5})(\d{4})$/;

  if (telefoneRegex.test(telefoneValor)) {
    telefone.value = telefoneValor.replace(telefoneRegex, '($1) $2-$3');
  }
});

let inputQuais = document.getElementById('quais');
let inputName = document.getElementById('name');
let inputEmail = document.getElementById('email');
let inputPhone = document.getElementById('phone');
let inputCompany = document.getElementById('company');

inputQuais.addEventListener('input', function (_e) {
  document.getElementById('quais_bottom_line').classList.remove('bottom-line-error');
  inputQuais.style.borderColor = '#d9d9d9';
});

inputName.addEventListener('input', function (_e) {
  document.getElementById('name_bottom_line').classList.remove('bottom-line-error');
  inputName.style.borderColor = '#d9d9d9';
});

inputEmail.addEventListener('input', function (_e) {
  document.getElementById('email_bottom_line').classList.remove('bottom-line-error');
  inputEmail.style.borderColor = '#d9d9d9';
});

inputPhone.addEventListener('input', function (_e) {
  document.getElementById('phone_bottom_line').classList.remove('bottom-line-error');
  phoneInput.style.borderColor = '#d9d9d9';
});

inputCompany.addEventListener('input', function (_e) {
  document.getElementById('company_bottom_line').classList.remove('bottom-line-error');
  inputCompany.style.borderColor = '#d9d9d9';
});

// limpar inputs do tipo select
let inputTypeAndSize = document.getElementById('type-and-size');
let inputLineOfBusiness = document.getElementById('line-of-business');

inputTypeAndSize.addEventListener('change', function (_e) {
  inputTypeAndSize.style.borderColor = '#d9d9d9';
});

inputLineOfBusiness.addEventListener('change', function (_e) {
  inputLineOfBusiness.style.borderColor = '#d9d9d9';
});

let botaoDoHero = document.getElementById('hero-cta-btn');

botaoDoHero.addEventListener('click', function (e) {
  e.preventDefault();
  window.location.href = process.env.HOST + '/registergrj';
});

let botaoDaSessaoDeClientes = document.getElementById('section-client-btn');

botaoDaSessaoDeClientes.addEventListener('click', function (e) {
  e.preventDefault();
  window.location.href = process.env.HOST + '/registergrj';
});

let yearTag = document.getElementById('year-tag');

yearTag.innerText = new Date().getFullYear();


// ------------ BOTOES DE CHECKLIST USADO ------------
let botaoSim = document.getElementById('botao-sim');
let botaoNao = document.getElementById('botao-nao');
botaoSim.addEventListener('click', marcarBotoes);
botaoNao.addEventListener('click', marcarBotoes);

function marcarBotoes (e) {
  e.preventDefault();
  if (e.target.id === 'botao-sim') {
    document.getElementById('campo_quais_checklists').classList.remove('d-none');
    document.getElementById('quais').required = true;
    botaoSim.classList.add('active');
    botaoNao.classList.remove('active');
    botoesDeChecklistUsado('yes');
  } else {
    document.getElementById('campo_quais_checklists').classList.add('d-none');
    document.getElementById('quais_bottom_line').classList.remove('bottom-line-error');
    document.getElementById('quais').value = '';
    document.getElementById('quais').required = false;
    botaoSim.classList.remove('active');
    botaoNao.classList.add('active');
    botoesDeChecklistUsado('no');
  }
}

function botoesDeChecklistUsado(usou) {
  let radios = document.querySelectorAll('input[name="choice-radio"]');

  radios.forEach(radio => {
    if (radio.id === usou) {
      radio.checked = true;
    } else {
      radio.checked = false;
    }
  });
}

// --------------------------- LOGICA DO FORMULARIO DO HERO --------------------------- //
function enviarFormularioHero(event) {
  event.preventDefault();
  // Obter os valores dos campos do formulário
  const dialCode = document.querySelector('.iti__selected-dial-code').innerHTML.split('+')[1];
  const celular = dialCode + document.getElementById('phone-hero').value.replace(/\D/g, '');
  const nome = document.getElementById('name-hero').value;
  const email = document.getElementById('email-hero').value;
  const empresa = document.getElementById('company-hero').value;
  const tipoETamanho = document.getElementById('type-and-size-hero');
  const tipoETamanhoSelecionado = tipoETamanho.options[tipoETamanho.selectedIndex].text;
  const segmento = document.getElementById('line-of-business-hero');
  const segmentoSelecionado = segmento.options[segmento.selectedIndex].text;
  const jaUsouOutroChecklistSelecionado = document.querySelector('input[name="choice-radio-hero"]:checked');
  const textoDaLabelDoRadioSelecionado = jaUsouOutroChecklistSelecionado ? document.querySelector('label[for="' + jaUsouOutroChecklistSelecionado.id + '"]').innerText : undefined;
  const quaisChecklistsJaUtilizou = "Não informado";

  const quantidadeDeCamposObrigatoriosNaoPreenchidos = [];

  if (document.getElementById('name-hero').required && document.getElementById('name-hero').value === '') {
    document.getElementById('name_bottom_line-hero').classList.add('bottom-line-error');
    document.getElementById('name-hero').style.borderColor = 'red';
    document.getElementById('name-hero').style.backgroundColor = '#FFC6C6';
    quantidadeDeCamposObrigatoriosNaoPreenchidos.push('name-hero');
  }

  const emailRegex = new RegExp('^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,6}$');
  if (document.getElementById('email-hero').required && (document.getElementById('email-hero').value === '' || !emailRegex.test(document.getElementById('email-hero').value))) {
    document.getElementById('email_bottom_line-hero').classList.add('bottom-line-error');
    document.getElementById('email-hero').style.borderColor = 'red';
    document.getElementById('email-hero').style.backgroundColor = '#FFC6C6';
    quantidadeDeCamposObrigatoriosNaoPreenchidos.push('email');
  }

  if (document.getElementById('phone-hero').required && document.getElementById('phone-hero').value === '') {
    document.getElementById('phone_bottom_line-hero').classList.add('bottom-line-error');
    document.getElementById('phone-hero').style.borderColor = 'red';
    document.getElementById('phone-hero').style.backgroundColor = '#FFC6C6';
    quantidadeDeCamposObrigatoriosNaoPreenchidos.push('phone');
  }
  
  const radioEmpresa = document.getElementById('hero-form-opcao-empresa');

  if (radioEmpresa.checked) {
    if (document.getElementById('company-hero').required && document.getElementById('company-hero').value === '') {
      document.getElementById('company_bottom_line-hero').classList.add('bottom-line-error');
      document.getElementById('company-hero').style.borderColor = 'red';
      document.getElementById('company-hero').style.backgroundColor = '#FFC6C6';
      quantidadeDeCamposObrigatoriosNaoPreenchidos.push('company-hero');
    }
  
    if (document.getElementById('type-and-size-hero').required && tipoETamanho.selectedIndex === 0) {
      document.getElementById('type-and-size-hero').style.borderColor = 'red';
      document.getElementById('type-and-size-hero').style.backgroundColor = '#FFC6C6';
      quantidadeDeCamposObrigatoriosNaoPreenchidos.push('type-and-size-hero');
    }
  
    if (document.getElementById('line-of-business-hero').required && segmento.selectedIndex === 0) {
      document.getElementById('line-of-business-hero').style.borderColor = 'red';
      document.getElementById('line-of-business-hero').style.backgroundColor = '#FFC6C6';
      quantidadeDeCamposObrigatoriosNaoPreenchidos.push('line-of-business-hero');
    }
  }


  if (quantidadeDeCamposObrigatoriosNaoPreenchidos.length > 0) return;

  const mensagem = document.querySelector('#mensagem-hero');

  const dadosDeAssinatura = {
    nome,
    email,
    celular,
    Empresa: empresa,
    TipoETamanho: tipoETamanhoSelecionado,
    Segmento: segmentoSelecionado,
    JaUsouOutroChecklist: textoDaLabelDoRadioSelecionado === undefined ? "Não respondido" : textoDaLabelDoRadioSelecionado,
    ChecklistsUtilizados: quaisChecklistsJaUtilizou,
    TipoDeAuditor: radioEmpresa.checked ? "Empresa" : "Independente",
  };

  fetch(process.env.API + '/api/ServicoDeEmail/PedirAssinatura', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(dadosDeAssinatura)
  })
  .then(response => {
    if (response.ok) {
      mensagem.textContent = 'Formulário enviado com sucesso!';
      mensagem.classList.remove('caixa_mensagem_erro');
      mensagem.classList.add('caixa_mensagem_sucesso');
      // redirecionar para a página de sucesso
      window.location.href = process.env.HOST + '/registergrj';
    } else {
      mensagem.textContent = 'Não foi possível enviar o formulário.';
      mensagem.classList.remove('caixa_mensagem_sucesso');
      mensagem.classList.add('caixa_mensagem_erro');
    }
  })
  .catch(error => {
    mensagem.textContent = 'Não foi possível enviar o formulário.';
    mensagem.classList.remove('caixa_mensagem_sucesso');
    mensagem.classList.add('caixa_mensagem_erro');
  })
  .finally(() => {
    mensagem.classList.remove('d-none');
  });
}

document.getElementById('enviar-formulario-hero').addEventListener('click', enviarFormularioHero);

let telefoneHero = document.getElementById('phone-hero');

telefoneHero.addEventListener('input', function (e) {
  const telefoneValor = telefone.value;
  const telefoneRegex = /^(\d{2})(\d{4,5})(\d{4})$/;

  if (telefoneRegex.test(telefoneValor)) {
    telefoneHero.value = telefoneValor.replace(telefoneRegex, '($1) $2-$3');
  }
});

let inputNameHero = document.getElementById('name-hero');
let inputEmailHero = document.getElementById('email-hero');
let inputPhoneHero = document.getElementById('phone-hero');
let inputCompanyHero = document.getElementById('company-hero');

inputNameHero.addEventListener('input', function (_e) {
  document.getElementById('name_bottom_line-hero').classList.remove('bottom-line-error');
  inputNameHero.style.borderColor = '#d9d9d9';
  inputNameHero.style.backgroundColor = '#e1f2ff';
});

inputEmailHero.addEventListener('input', function (_e) {
  document.getElementById('email_bottom_line-hero').classList.remove('bottom-line-error');
  inputEmailHero.style.borderColor = '#d9d9d9';
  inputEmailHero.style.backgroundColor = '#e1f2ff';
});

inputPhoneHero.addEventListener('input', function (_e) {
  document.getElementById('phone_bottom_line-hero').classList.remove('bottom-line-error');
  phoneInput.style.borderColor = '#d9d9d9';
  phoneInput.style.backgroundColor = '#e1f2ff';
});

inputCompanyHero.addEventListener('input', function (_e) {
  document.getElementById('company_bottom_line-hero').classList.remove('bottom-line-error');
  inputCompanyHero.style.borderColor = '#d9d9d9';
  inputCompanyHero.style.backgroundColor = '#e1f2ff';
});

// limpar inputs do tipo select
let inputTypeAndSizeHero = document.getElementById('type-and-size-hero');
let inputLineOfBusinessHero = document.getElementById('line-of-business-hero');

inputTypeAndSizeHero.addEventListener('change', function (_e) {
  inputTypeAndSizeHero.style.borderColor = '#d9d9d9';
  inputTypeAndSizeHero.style.backgroundColor = '#e1f2ff';
});

inputLineOfBusinessHero.addEventListener('change', function (_e) {
  inputLineOfBusinessHero.style.borderColor = '#d9d9d9';
  inputLineOfBusinessHero.style.backgroundColor = '#e1f2ff';
});
